import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/shortlinks/';

function list(params) {
  return storeRequest.get(path, {
    params: { ...params },
  });
}

function get(id) {
  return storeRequest.get(path + id);
}

function create(data) {
  return storeRequest.post(path, data);
}

function update(data) {
  return storeRequest.put(path + data.id, data);
}

function deleteByIds(ids) {
  return storeRequest.delete(path + '?ids=' + ids);
}

function count() {
  return storeRequest.get(path + 'count');
}

function bulk(data) {
  return storeRequest.post(path + 'bulk', data);
}

function check(data) {
  return storeRequest.post(path + 'check', data);
}

export const shortlinkApi = {
  list,
  create,
  update,
  get,
  deleteByIds,
  count,
  bulk,
  check,
};
